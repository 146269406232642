






















import { Component, Mixins, Watch } from 'vue-property-decorator'
import { PaymentModule } from '_index/modules/payment'
import { ShareMixin } from '_index/mixins/share'
import { LoyoutModule } from '@/store/modules/layout'
import {
  callAppFunc_closeCurrentWebview,
  callAppFunc_closeWebViewPopUp
} from '@/common/utils/bridge'
import { getScreen } from '@/common/utils'
@Component({
  name: 'PaymentResult'
})
export default class PaymentResult extends Mixins(ShareMixin) {
  hide = false
  getPayOk = false
  timer: number | undefined
  result = this.$route.query.result
  get channel() {
    return this.$route.query.channel
  }

  async created() {
    this.getPayOk = false
  }
  backToLast() {
    this.$router.back()
  }
  goOrederList() {
    this.$router.push({
      name: 'exchangeRecord'
    })
  }
  /**
   * 修改文字
   * @returns {*}
   */
  get isShowTitle() {
    return this.result == '1'
      ? {
          one: '兑换成功',
          two: '兑换成功，请到我的订单中查看',
          bg: require('_index/assets/kigo/payment/pay_success_img@2x.png')
        }
      : {
          one: '兑换失败',
          two: '兑换失败，积分已退回',
          bg: require('_index/assets/kigo/payment/pay_fail_img@2x.png')
        }
  }

  @Watch('isShowTitle', {
    immediate: true
  })
  onChangeIsShowTitle() {
    LoyoutModule.setTitle(this.isShowTitle.one)
  }
}
